<script setup>
import { onMounted, ref } from "vue";
import { vMaska } from "maska";

const model = defineModel({
    type: String,
    required: true,
});

let props = defineProps({
    maskaType: { type: String, default: null },
    maska: { type: String, default: null },
    maxlen: { default: 255 },
});

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute("autofocus")) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        v-if="maska"
        v-maska
        :maxlength="maxlen"
        :type="props.maskaType"
        data-maska="Z"
        :data-maska-tokens="`Z:${props.maska}:multiple`"
        class="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm"
        v-model="model"
        ref="input"
    />

    <input
        v-else
        :maxlength="maxlen"
        class="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm"
        v-model="model"
        ref="input"
    />
</template>
